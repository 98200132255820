:root {
  // Primary colors
  --astronaut-blue: rgb(4, 73, 115); // Astronaut Blue
  --allports: rgb(0, 118, 168); // Allports
  --chateau-green: rgb(57, 181, 74); // Chateau Green
  --white: rgb(255, 255, 255); // White
  --mercury: rgb(224, 224, 224); // Mercury
  --silver: rgb(204, 204, 204); // Silver
  --dove-gray: rgb(102, 102, 102); // Dove Gray
  --mine-shaft: rgb(51, 51, 51); // Mine Shaft

  // Secondary colors
  --concrete: rgb(242, 242, 242); // Concrete
  --gray: rgb(140, 140, 140); // Gray
  --boulder: rgb(118, 118, 118); // Boulder
  --gull-gray: rgb(150, 171, 187); // Gull Gray
  --lochmara: rgb(0, 118, 168); // Lochmara
  --lochmara-light: rgba(0, 118, 168, 0.2);

  // Alert colors
  --guardsman-red: rgb(201, 10, 0); // Guardsman Red
  --yellow-sea: rgb(245, 165, 3); // Yellow Sea
  --turbo: rgb(249, 225, 0); // Turbo
  --atlantis: rgb(140, 198, 63); // Atlantis

  // Accent colors
  --amaranth: rgb(242, 56, 90); // Amaranth
  --violet-eggplant: rgb(149, 27, 128); // Violet Eggplant
  --pelorous: rgb(54, 177, 191); // Pelorous

  // Other colors
  --gainsboro: rgb(218, 218, 218);
  --deep-blue: rgb(0, 59, 92);
  --light-red: rgb(255, 237, 235);
  --spanish-gray: rgb(153, 153, 153);
  --light-green: rgb(242, 255, 247);
  --carmine-pink-light: rgba(235, 63, 63, 0.1);
  --black1: rgba(0, 0, 0, 0.3);
  --black2: rgba(0, 0, 0, 0.5);
}
